import mock from "src/utils/mock";

let activeEmailAlerts = [
  "licenseServerDown",
  "outOfLicenses",
  "lowOnLicenses",
  "licenseExpired"
];
let activeSmsAlerts = ["lowOnLicenses", "licenseExpiring"];
let emailAddresses = ["test@civilgeo.com"];
let phoneNumbers = ["8592376597", "4089836551"];

mock.onGet("/api/licenseAlerts/activeAlerts").reply(request => {
  return [200, { activeEmailAlerts, activeSmsAlerts }];
});

mock.onPost("/api/licenseAlerts/activeAlerts/set").reply(request => {
  try {
    const {
      activeEmailAlerts: _activeEmailAlerts,
      activeSmsAlerts: _activeSmsAlerts
    } = JSON.parse(request.data);

    activeEmailAlerts = _activeEmailAlerts;
    activeSmsAlerts = _activeSmsAlerts;

    return [200, { activeEmailAlerts, activeSmsAlerts }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock
  .onGet("/api/licenseAlerts/contactDetails")
  .reply(200, { emailAddresses, phoneNumbers });

mock.onPost("/api/licenseAlerts/contactDetails/set").reply(request => {
  try {
    const {
      emailAddresses: _emailAddresses,
      phoneNumbers: _phoneNumbers
    } = JSON.parse(request.data);

    emailAddresses = _emailAddresses;
    phoneNumbers = _phoneNumbers;

    return [200, { emailAddresses, phoneNumbers }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
