import _ from "lodash";
import axios from "src/utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tickets: [],
  details: null
};

const slice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    getAllTickets(state, action) {
      const tickets = action.payload;

      state.tickets = tickets;
    },
    getTicketDetails(state, action) {
      const details = action.payload;

      state.details = details;
    }
  }
});

export const reducer = slice.reducer;

export const getAllTickets = (closed) => async dispatch => {
  const response = await axios.get("/api/tickets/all?closed="+ closed);

  dispatch(slice.actions.getAllTickets(response.data));
};

export const getTicketDetails = (ticketId) => async dispatch => {
  const response = await axios.get("/api/tickets/details?ticketId="+ ticketId);

  dispatch(slice.actions.getTicketDetails(response.data));
};

export default slice;
