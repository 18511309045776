import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";

const initialState = {
  groups: [],
  isDeleteDialogOpen: false,
  selectedGroupIds: null,
  showWarning: false, 
};

const slice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    getGroups(state, action) {
      const groups = action.payload;

      state.groups = groups;
     
    },
    selectGroupsForDelete(state, action) {
      const { groupIds, enqueueSnackbar  } = action.payload;
 
      const groupExists = groupIds.some(groupId => 
        state.groups.some(group => group.parentGroup === groupId)
      );

      if (groupExists) {
        state.isDeleteDialogOpen = false;
        state.showWarning = true; // Set flag to true when warning should be shown
      } else {
        state.isDeleteDialogOpen = true;
        state.selectedGroupIds = groupIds;
      }
    },
    createGroup(state, action) {
      const group = action.payload;

      state.groups = [...state.groups, group];
    },
    updateGroup(state, action) {
      const group = action.payload;

      state.groups = _.map(state.groups, _group => {
        if (_group.id === group.id) {
          return group;
        }

        return _group;
      });
    },
    deleteGroups(state, action) {
      const { groupIds } = action.payload;

      state.groups = _.reject(state.groups, email =>
        groupIds.includes(email.id)
      );
    },
    closeDialog(state) {
      state.isDeleteDialogOpen = false;
      state.selectedGroupIds = null;
    },
    clearWarning(state) {
      state.showWarning = false; // Action to clear the warning flag
    }
  }
});

export const reducer = slice.reducer;

export const getGroups = () => async dispatch => {
  const response = await axios.get("/api/admin/groups");

  dispatch(slice.actions.getGroups(response.data));
};

export const createGroup = data => async dispatch => {
  const response = await axios.post("/api/admin/groups/new", data);

  dispatch(slice.actions.createGroup(response.data));
};

export const selectGroupsForDelete = groupIds => async dispatch => {
  dispatch(slice.actions.selectGroupsForDelete({ groupIds }));
};

export const updateGroup = (groupId, update) => async dispatch => {
  const response = await axios.post("/api/admin/groups/update", {
    groupId,
    update
  });

  dispatch(slice.actions.updateGroup(response.data));
};

export const deleteGroups = groupIds => async dispatch => {
  await axios.post("/api/admin/groups/remove", {
    groupIds
  });

  dispatch(slice.actions.deleteGroups({ groupIds }));
};

export const closeDialog = () => dispatch => {
  dispatch(slice.actions.closeDialog());
};
export const clearWarning = () => dispatch => {
  dispatch(slice.actions.clearWarning());
};
export default slice;
