import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";

const initialState = {
  settings: null,
  isDeleteDialogOpen: false,
  selectedUserIds: null
};

const slice = createSlice({
  name: "companySettings",
  initialState,
  reducers: {
    getCompanySettings(state, action) {
      const settings = action.payload;
      state.settings = settings
    },

    updateCompanySettings(state, action) {
      const settings = action.payload;
      state.settings = settings
    },
  }
});

export const reducer = slice.reducer;

export const updateCompanySettings = data => async dispatch => {
  const response = await axios.post("/api/companySettings", data);

  dispatch(slice.actions.updateCompanySettings(response.data));
};

export const resetCompanySettings = () => async dispatch => {
  const response = await axios.post("/api/companySettings/reset");

  dispatch(slice.actions.updateCompanySettings(response.data));
};


export const getCompanySettings = () => async dispatch => {
  const response = await axios.get("/api/companySettings");

  dispatch(slice.actions.getCompanySettings(response.data));
};

export default slice;
