import React, { Fragment, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AdminGuard from "src/guards/AdminGuard";
import AuthGuard from "src/guards/AuthGuard";
import DashboardLayout from "src/layouts/DashboardLayout";
import ErrorBoundary from "src/components/ErrorBoundary";
import GuestGuard from "src/guards/GuestGuard";
import AutoLoginGuard from "src/guards/AutoLoginGuard";
import LoadingScreen from "src/components/LoadingScreen";
import PasswordGuard from "src/guards/PasswordGuard";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={props => (
              <ErrorBoundary>
                <Guard>
                  <Layout>
                    <ErrorBoundary>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </ErrorBoundary>
                  </Layout>
                </Guard>
              </ErrorBoundary>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ["/login", "/passwordReset", "/magicLink", "/timeout"],
    component: lazy(() => import("src/views/auth/LoginView"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ["/magic/:key"],
    component: lazy(() => import("src/views/auth/MagicLoginView"))
  },
  {
    exact: true,
    guard: AutoLoginGuard,
    path: ["/autoLogin/:key"],
    component: lazy(() => import("src/views/auth/AutoLoginView"))
  },
  {
    exact: true,
    guard: PasswordGuard,
    path: "/app/changePassword",
    component: lazy(() => import("src/views/auth/ChangePasswordView"))
  },
  {
    exact: true,
    guard: PasswordGuard,
    path: "/app/twoFactorVerification",
    component: lazy(() => import("src/views/auth/TwoFactorView"))
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app/account",
        component: lazy(() => import("src/views/auth/AccountView"))
      },
      {
        exact: true,
        path: "/app/alerts",
        component: lazy(() => import("src/views/alerts/AlertsView"))
      },
      {
        exact: true,
        path: "/app/training",
        component: lazy(() => import("src/views/training/TrainingView"))
      },
      {
        exact: true,
        path: "/app/home",
        component: lazy(() => import("src/views/home/HomeView"))
      },
      {
        exact: true,
        path: ["/app/products/all", "/app/releaseNotes/:key"],
        component: lazy(() => import("src/views/software/Downloads"))
      },
      {        
        exact: true,
        path: "/app/products/assigned",
        component: lazy(() => import("src/views/productAccess"))
      },
      {        
        exact: true,
        path: "/app/tickets/all",
        component: lazy(() => import("src/views/tickets/ViewTickets"))
      },
      {        
        exact: true,
        path: "/app/tickets/new",
        component: lazy(() => import("src/views/tickets/CreateTicket"))
      },
      {        
        exact: true,
        path: "/app/tickets/detail/:ticketId",
        component: lazy(() => import("src/views/tickets/Details"))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: "/app/licenses",
        component: () => <Redirect to="/app/licenses/all" />
      },
      {
        exact: true,
        path: "/app/licenses/add",
        guard: AdminGuard,
        component: lazy(() => import("src/views/licenses/AddLicensesView"))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: "/app/licenses/all",
        component: lazy(() => import("src/views/licenses/AllLicensesView"))
      },
      {
        exact: true,
        path: "/app/licenses/used",
        guard: AdminGuard,        
        component: lazy(() => import("src/views/licenses/UsedLicensesView"))
      },
      {
        exact: true,
        path: "/app/usage/detail",
        guard: AdminGuard,
        component: lazy(() => import("src/views/usage/UsageDetailsView"))
      },
      {
        exact: true,
        path: "/app/usage",
        guard: AdminGuard,
        component: () => <Redirect to="/app/usage/chart" />
      },
      {
        exact: true,
        path: "/app/usage/chart",
        guard: AdminGuard,
        component: lazy(() => import("src/views/usage/ChartView"))
      },
      {
        exact: true,
        path: "/app/usage/heatMap",
        guard: AdminGuard,
        component: lazy(() => import("src/views/usage/HeatMapView"))
      },
      {
        path: "/app/admin",
        guard: AdminGuard,
        routes: [
          {
            exact: true,
            path: "/app/admin",
            component: () => <Redirect to="/app/admin/users/manage" />
          },
          {
            exact: true,
            path: "/app/admin/users",
            component: () => <Redirect to="/app/admin/users/manage" />
          },
          {
            exact: true,
            path: "/app/admin/users/manage",
            component: lazy(() =>
              import("src/views/admin/users/users/ManageView")
            )
          },
          {
            exact: true,
            path: [
              "/app/admin/users/add",
              "/app/admin/users/edit/:userIdEditing"
            ],
            component: lazy(() => import("src/views/admin/users/users/AddView"))
          },
          {
            exact: true,
            path: [
              "/app/admin/users/addMultiple"
            ],
            component: lazy(() => import("src/views/admin/users/users/AddMultipleView"))
          },
          {
            exact: true,
            path: [
              "/app/admin/users/addMultipleResult"
            ],
            component: lazy(() => import("src/views/admin/users/users/AddMultipleResultView"))
          },
          {
            exact: true,
            path: "/app/admin/groups",
            component: () => <Redirect to="/app/admin/groups/manage" />
          },
          {
            exact: true,
            path: "/app/admin/groups/manage",
            component: lazy(() =>
              import("src/views/admin/users/groups/ManageView")
            )
          },
          {
            exact: true,
            path: [
              "/app/admin/groups/add",
              "/app/admin/groups/edit/:groupIdEditing"
            ],
            component: lazy(() =>
              import("src/views/admin/users/groups/AddView")
            )
          },
          {
            exact: true,
            path: "/app/admin/emails",
            component: () => <Redirect to="/app/admin/emails/manage" />
          },
          {
            exact: true,
            path: [
              "/app/admin/emails/manage",
              "/app/admin/emails/manage#:fragment"
            ],
            component: lazy(() =>
              import("src/views/admin/users/emails/ManageView")
            )
          },
          {
            exact: true,
            path: [
              "/app/admin/emails/add",
              "/app/admin/emails/edit/:emailIdEditing"
            ],
            component: lazy(() =>
              import("src/views/admin/users/emails/AddView")
            )
          },
          {
            exact: true,
            path: "/app/admin/customfields",
            component: () => <Redirect to="/app/admin/customfields/manage" />
          },
          {
            exact: true,
            path: "/app/admin/customfields/manage",
            component: lazy(() =>
              import("src/views/admin/users/customFields/ManageView")
            )
          },
          {
            exact: true,
            path: "/app/admin/licenseManagement",
            component: lazy(() =>
              import("src/views/admin/licenseManagement/ProductView")
            )
          },
          {
            exact: true,
            path: "/app/admin/deviceManagement",
            component: lazy(() =>
              import("src/views/admin/deviceManagement")
            )
          },
          {
            exact: true,
            path: "/app/admin/config/portal",
            component: lazy(() =>
              import("src/views/admin/config/portalConfigView")
            )
          },
          {
            component: () => <Redirect to="/404" />
          }
        ]
      },
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/home" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    component: () => <Redirect to="/404" />
  }
];

export default routes;
//
