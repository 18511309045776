import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { ENABLE_REDUX_DEV_TOOLS } from "src/constants";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: ENABLE_REDUX_DEV_TOOLS
});
store.subscribe(() => store.getState());

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
