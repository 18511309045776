import jwt from "jsonwebtoken";
import mock from "src/utils/mock";
import wait from "src/utils/wait";

const JWT_SECRET = "top-secret-key";
const JWT_EXPIRES_IN = "2 days";

const users = [
  {
    id: "5e86809283e28b96d2d38537",
    firstName: "Test",
    lastName: "User",
    avatar: "/static/images/avatars/avatar_4.png",
    emailAddress: "test@civilgeo.com",
    password: "password123",
    groupId: "5e8882f1f0c9216396e05a9c",
    admin: true
  }
];

mock.restore();

mock.onPost("/api/account/login").reply(async request => {
  try {
    await wait(1000);

    const { emailAddress, password } = JSON.parse(request.data);
    const user = users.find(_user => _user.emailAddress === emailAddress);

    if (!user) {
      return [400, { message: "Please check your email address and password" }];
    }

    if (user.password !== password) {
      return [400, { message: "Invalid password" }];
    }

    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [
      200,
      {
        accessToken,
        user
      }
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/account/resetPassword").reply(async request => {
  return [200, {}];
});

mock.onGet("/api/account/me").reply(request => {
  try {
    const { Authorization } = request.headers;

    if (!Authorization) {
      return [401, { message: "Authorization token missing" }];
    }

    const accessToken = Authorization.split(" ")[1];
    const { userId } = jwt.verify(accessToken, JWT_SECRET);
    const user = users.find(_user => _user.id === userId);

    if (!user) {
      return [401, { message: "Invalid authorization token" }];
    }

    return [
      200,
      {
        user
      }
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
