import React from "react";
import packageJson from "src/../package.json";
import preval from "preval.macro";

const buildTimeStamp = preval`module.exports = new Date().toLocaleString();`;

const getDebugInfo = () => {
  return `CivilGEO Customer Portal version: ${packageJson.version}, React version: ${React.version}, built: ${buildTimeStamp}`;
};

export default getDebugInfo;
