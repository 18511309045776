import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
  activeEmailAlerts: null,
  activeSmsAlerts: null,
  emailAddresses: null,
  phoneNumbers: null
};

const slice = createSlice({
  name: "licenseAlerts",
  initialState,
  reducers: {
    getActiveAlerts(state, action) {
      const { activeEmailAlerts, activeSmsAlerts } = action.payload;

      state.activeEmailAlerts = activeEmailAlerts;
      state.activeSmsAlerts = activeSmsAlerts;
    },
    setActiveAlerts(state, action) {
      const { activeEmailAlerts, activeSmsAlerts } = action.payload;

      state.activeEmailAlerts = activeEmailAlerts;
      state.activeSmsAlerts = activeSmsAlerts;
    },
    getContactDetails(state, action) {
      const { emailAddresses, phoneNumbers } = action.payload;

      state.emailAddresses = emailAddresses;
      state.phoneNumbers = phoneNumbers;
    },
    setContactDetails(state, action) {
      const { emailAddresses, phoneNumbers } = action.payload;

      state.emailAddresses = emailAddresses;
      state.phoneNumbers = phoneNumbers;
    }
  }
});

export const reducer = slice.reducer;

export const getActiveAlerts = () => async dispatch => {
  const response = await axios.get("/api/licenseAlerts/activeAlerts");

  dispatch(slice.actions.getActiveAlerts(response.data));
};

export const setActiveAlerts = (
  activeEmailAlerts,
  activeSmsAlerts
) => async dispatch => {
  const response = await axios.post("/api/licenseAlerts/activeAlerts/set", {
    activeEmailAlerts,
    activeSmsAlerts
  });

  dispatch(slice.actions.setActiveAlerts(response.data));
};

export const getContactDetails = () => async dispatch => {
  const response = await axios.get("/api/licenseAlerts/contactDetails");

  dispatch(slice.actions.getContactDetails(response.data));
};

export const setContactDetails = (
  emailAddresses,
  phoneNumbers
) => async dispatch => {
  const response = await axios.post("/api/licenseAlerts/contactDetails/set", {
    emailAddresses,
    phoneNumbers
  });

  dispatch(slice.actions.setContactDetails(response.data));
};

export default slice;
