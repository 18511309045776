import { leftPadArray, rightPadArray } from "src/utils/padArray";

import _ from "lodash";
import mock from "src/utils/mock";
import moment from "moment";

const LICENSES_USED_MAX_VALUE = 70;
const USAGE_LEVEL_MAX_VALUE = 100;

mock.onPost("/api/admin/usage/heatMap").reply(request => {
  try {
    //console.log(JSON.stringify(JSON.parse(request.data).filters));
    const { filters } = JSON.parse(request.data);

    let dataPoints = [];

    const pushWeekDataPoints = (date, weekDataPoints) => {
      dataPoints.push({
        name: moment(date).day(0),
        data: weekDataPoints
      });
    };

    let rangeEnd = moment();
    if (filters.range === "custom") {
      rangeEnd = moment(filters.customRange.end);
    }

    let rangeStart;
    switch (filters.range) {
      case "lastMonth":
        rangeStart = moment(rangeStart).subtract(1, "months");
        break;
      case "lastTwoMonths":
        rangeStart = moment(rangeStart).subtract(2, "months");
        break;
      case "lastThreeMonths":
        rangeStart = moment(rangeStart).subtract(3, "months");
        break;
      case "custom":
        rangeStart = moment(filters.customRange.start);
        break;
      case "lastTwoWeeks":
      default:
        rangeStart = moment(rangeStart).subtract(2, "weeks");
        break;
    }

    const dataPointsToGenerate = rangeEnd.diff(rangeStart, "days");
    const excludeWeekends =
      filters.range === "custom" && filters.customRange.excludeWeekends;

    let weekDataPoints = [];
    let dataPointTime = rangeStart;
    let timeAsString;
    for (let i = 0; i < dataPointsToGenerate; i++) {
      dataPointTime.add(1, "days");

      timeAsString = dataPointTime.toDate().toString();
      const dayOfWeek = dataPointTime.day();
      if (!(excludeWeekends && (dayOfWeek === 0 || dayOfWeek === 6))) {
        const value = _.random(0, USAGE_LEVEL_MAX_VALUE);

        weekDataPoints.push(value);
      }

      if (dayOfWeek === 6) {
        weekDataPoints = leftPadArray(
          weekDataPoints,
          excludeWeekends ? 5 : 7,
          -1
        );
        pushWeekDataPoints(timeAsString, weekDataPoints);
        weekDataPoints = [];
      }
    }

    if (weekDataPoints.length) {
      weekDataPoints = rightPadArray(
        weekDataPoints,
        excludeWeekends ? 5 : 7,
        -1
      );

      pushWeekDataPoints(timeAsString, weekDataPoints);
    }

    console.log(
      JSON.stringify({
        chartData: dataPoints,
        totalLicenses: LICENSES_USED_MAX_VALUE
      })
    );

    return [
      200,
      {
        chartData: dataPoints,
        totalLicenses: LICENSES_USED_MAX_VALUE
      }
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
