import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";

const initialState = {
  fields: [],
  isEditDialogOpen: false,
  isDeleteDialogOpen: false,
  selectedFieldIds: null
};

const slice = createSlice({
  name: "customFields",
  initialState,
  reducers: {
    getFields(state, action) {
      const fields = action.payload;

      state.fields = fields;
    },
    createField(state, action) {
      const field = action.payload;

      state.fields = [...state.fields, field];
    },
    selectFieldForEdit(state, action) {
      const { fieldId } = action.payload;

      state.isEditDialogOpen = true;
      state.selectedFieldIds = fieldId;
    },
    selectFieldsForDelete(state, action) {
      const {fieldIds = null} = action.payload;

      state.isDeleteDialogOpen = true;
      state.selectedFieldIds = fieldIds;
    },
    updateField(state, action) {
      const field = action.payload;

      state.fields = _.map(state.fields, _field => {
        if (_field.id === field.id) {
          return field;
        }

        return _field;
      });
    },
    deleteFields(state, action) {
      const { fieldIds } = action.payload;

      state.fields = _.reject(state.fields, field =>
        fieldIds.includes(field.id)
      );
    },
    openEditDialog(state) {
      state.isEditDialogOpen = true;
    },
    closeDialog(state) {
      state.isEditDialogOpen = false;
      state.isDeleteDialogOpen = false;
      state.selectedFieldIds = null;
    }
  }
});

export const reducer = slice.reducer;

export const getFields = () => async dispatch => {
  const response = await axios.get("/api/admin/customFields");

  dispatch(slice.actions.getFields(response.data));
};

export const createField = data => async dispatch => {
  const response = await axios.post("/api/admin/customFields/new", data);

  dispatch(slice.actions.createField(response.data));
};

export const selectFieldForEdit = fieldId => async dispatch => {
  dispatch(slice.actions.selectFieldForEdit({ fieldId }));
};

export const selectFieldsForDelete = fieldIds => async dispatch => {
  dispatch(slice.actions.selectFieldsForDelete({ fieldIds }));
};

export const updateField = (fieldId, update) => async dispatch => {
  const response = await axios.post("/api/admin/customFields/update", {
    fieldId,
    update
  });

  dispatch(slice.actions.updateField(response.data));
};

export const deleteFields = fieldIds => async dispatch => {
  await axios.post("/api/admin/customFields/remove", {
    fieldIds
  });

  dispatch(slice.actions.deleteFields({ fieldIds }));
};

export const openEditDialog = () => dispatch => {
  dispatch(slice.actions.openEditDialog());
};

export const closeDialog = () => dispatch => {
  dispatch(slice.actions.closeDialog());
};

export default slice;
