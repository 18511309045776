import React, { useEffect, useRef, useState } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import base64ToImage from "src/utils/imageUtils";
import {
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import useAuth from "src/hooks/useAuth";
import UserAvatar from "src/components/UserAvatar";
import IdleTimeOutHandler from "src/components/IdleTimoutHandler";

const useStyles = makeStyles(theme => ({
  userIcon: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  accountMenuItem: {
    color: "black",
    textDecoration: "none"
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout} = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [ _user , setUser] = useState(user);
  

  const _updateProfileImage=(e)=>{
    setUser(e.detail.user);
  }

  useEffect(()=>{
    setUser(user);
    window.addEventListener("updateProfileImage",_updateProfileImage);
  },[]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const[isActive,setIsActive]=useState(true)

  const handleLogout = async () => {
    try {
      handleClose();
      logout();
      history.push("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout");
    }
  };

  const handleTimeout = async () => {
    try {
      handleClose();
      logout();
      history.push("/timeout");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout");
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        {/* 20 minutes of idle timeout */}
        <IdleTimeOutHandler
          timeOutInterval={20 * 60 * 1000}
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(false);
          }}
          onLogout={() => {
            handleTimeout();
          }}
        />

        <UserAvatar
          firstName={_user.firstName}
          lastName={_user.lastName}
          image={base64ToImage(_user.avatar)}
          className={classes.userIcon}
        />
        {/* <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {_user.firstName}
          </Typography>
        </Hidden> */}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={open}
      >
        <MenuItem>
          <RouterLink className={classes.accountMenuItem} to="/app/account">
            My Account
          </RouterLink>
        </MenuItem>
        <MenuItem>
          <RouterLink
            className={classes.accountMenuItem}
            to="/app/changePassword"
          >
            Change Password
          </RouterLink>
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
