import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
const AutoLoginGuard = ({ children }) => {
  return <>{children}</>; 
};

AutoLoginGuard.propTypes = {
  children: PropTypes.node
};

export default AutoLoginGuard;
