import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import mock from "src/utils/mock";

let fields = [
  {
    id: "5e8882e440f6322fa399eeb8",
    name: "Telephone"
  },
  {
    id: "5e8882eb5f8ec686220ff131",
    name: "Office"
  },
  {
    id: "5e8882f1f0c9216396e05a9b",
    name: "Years with Company"
  }
];

mock.onGet("/api/admin/customFields").reply(request => {
  return [200, { fields }];
});

mock.onPost("/api/admin/customFields/new").reply(request => {
  try {
    const { name } = JSON.parse(request.data);
    const field = {
      id: uuidv4(),
      name
    };

    fields = [...fields, field];

    return [200, { field }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/admin/customFields/update").reply(request => {
  try {
    const { fieldId, update } = JSON.parse(request.data);
    let field = null;

    fields = _.map(fields, _field => {
      if (_field.id === fieldId) {
        _.assign(_field, { ...update });
        field = _field;
      }

      return _field;
    });

    return [200, { field }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/admin/customFields/remove").reply(request => {
  try {
    const { fieldIds } = JSON.parse(request.data);

    fields = _.reject(fields, field => fieldIds.includes(field.id));

    return [200, { fieldIds }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
