import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
  licenseOverview: []
};

const slice = createSlice({
  name: "licenseOverview",
  initialState,
  reducers: {
    getLicenseOverview(state, action) {
      const licenseOverview = action.payload;
      state.licenseOverview = licenseOverview;
    }
  }
});

export const reducer = slice.reducer;

export const getLicenseOverview = () => async dispatch => {
  const response = await axios.get("/api/licenses/overview");

  dispatch(slice.actions.getLicenseOverview(response.data));
};

export default slice;
