import React from "react";
import useAuth from "../hooks/useAuth";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const AdminGuard = ({ children }) => {
  const { user } = useAuth();

  if (!user.admin) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
