import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";

const initialState = {
  emails: [],
  defaultEmail: null,
  emailReplyAddress: null,
  isDeleteDialogOpen: false,
  selectedEmailIds: null
};

const slice = createSlice({
  name: "welcomeEmails",
  initialState,
  reducers: {
    getEmails(state, action) {
      const emails = action.payload;

      state.emails = emails;
    },
    selectEmailsForDelete(state, action) {
      const { emailIds = null } = action.payload;

      state.isDeleteDialogOpen = true;
      state.selectedEmailIds = emailIds;
    },
    createEmail(state, action) {
      const email = action.payload;

      state.emails = [...state.emails, email];
    },
    updateEmail(state, action) {
      const email = action.payload;

      state.emails = _.map(state.emails, _email => {
        if (_email.id === email.id) {
          return email;
        }

        return _email;
      });
    },
    deleteEmails(state, action) {
      const {emailIds = null} = action.payload;

      state.emails = _.reject(state.emails, email =>
        emailIds.includes(email.id)
      );
    },
    getDefaultEmail(state, action) {
      const defaultEmail = action.payload;

      state.defaultEmail = defaultEmail;
    },
    setDefaultEmail(state, action) {
      const defaultEmail = action.payload;

      state.defaultEmail = defaultEmail;
    },
    getEmailReplyAddress(state, action) {
      const emailReplyAddress = action.payload;

      state.emailReplyAddress = emailReplyAddress;
    },
    setEmailReplyAddress(state, action) {
      const emailReplyAddress = action.payload;

      state.emailReplyAddress = emailReplyAddress;
    },
    closeDialog(state) {
      state.isDeleteDialogOpen = false;
      state.selectedFieldIds = null;
    }
  }
});

export const reducer = slice.reducer;

export const getEmails = () => async dispatch => {
  const response = await axios.get("/api/admin/welcomeEmails/emails");

  dispatch(slice.actions.getEmails(response.data));
};

export const createEmail = data => async dispatch => {
  const response = await axios.post(
    "/api/admin/welcomeEmails/emails/new",
    data
  );

  dispatch(slice.actions.createEmail(response.data));
};

export const selectEmailsForDelete = emailIds => async dispatch => {
  dispatch(slice.actions.selectEmailsForDelete({ emailIds }));
};

export const updateEmail = (emailId, update) => async dispatch => {  
  const response = await axios.post("/api/admin/welcomeEmails/emails/update", {
    emailId,
    update
  });

  dispatch(slice.actions.updateEmail(response.data));
};

export const deleteEmails = emailIds => async dispatch => {
  await axios.post("/api/admin/welcomeEmails/emails/remove", {
    emailIds
  });

  dispatch(slice.actions.deleteEmails({ emailIds }));
};

export const getDefaultEmail = () => async dispatch => {
  const response = await axios.get("/api/admin/welcomeEmails/defaultEmail");

  dispatch(slice.actions.getDefaultEmail(response.data));
};

export const setDefaultEmail = emailId => async dispatch => {
  const response = await axios.post(
    "/api/admin/welcomeEmails/defaultEmail/update",
    {
      emailId
    }
  );

  dispatch(slice.actions.getDefaultEmail(response.data));
};

export const getEmailReplyAddress = () => async dispatch => {
  const response = await axios.get("/api/admin/welcomeEmails/replyEmail");

  dispatch(slice.actions.getEmailReplyAddress(response.data));
};

export const setEmailReplyAddress = emailAddress => async dispatch => {
  const response = await axios.post(
    "/api/admin/welcomeEmails/replyEmail/update",
    {
      emailAddress
    }
  );

  dispatch(slice.actions.setEmailReplyAddress(response.data));
};

export const closeDialog = () => dispatch => {
  dispatch(slice.actions.closeDialog());
};

export default slice;
