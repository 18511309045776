/* eslint-disable no-use-before-define */
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles
} from "@material-ui/core";
import React, { useEffect } from "react";

import NavItem from "./NavItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import base64ToImage from "src/utils/imageUtils";
import { useDispatch, useSelector } from "src/store";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { getCompanySettings } from "src/slices/admin/companySettings";
import {
  Alert01Icon,
  AnalyticsUpIcon,
  ChartLineData02Icon,
  CloudDownloadIcon,
  ComputerSettingsIcon,
  DashboardSquare01Icon,
  LicenseIcon,
  LicenseMaintenanceIcon,
  LicenseThirdPartyIcon,
  MailAccount01Icon,
  MicrosoftAdminIcon,
  ProfileIcon,
  PropertyEditIcon,
  Settings05Icon,
  SoftwareIcon,
  TaskAdd02Icon,
  TeachingIcon,
  UserGroupIcon,
  UserSettings01Icon
} from "@hugeicons/react-pro";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from 'src/AppInsights';


const items = [

  {
    title: "Dashboard",
    icon: DashboardSquare01Icon,
    href: "/app/home"
  },
  {
    title: "Software Downloads",
    icon: CloudDownloadIcon,
    href: "/app/products/all"
  },
  {
    title: "Assigned Products",
    icon: UserGroupIcon,
    href: "/app/products/assigned"
  },
  {
    title: "Licenses",
    icon: LicenseIcon,
    adminOnly: true,
    href: "/app/licenses/all"
  },
  {
    title: "Add Licenses",
    icon: TaskAdd02Icon,
    adminOnly: true,
    href: "/app/licenses/add"
  },
  {
    title: "Product Usage",
    icon: SoftwareIcon,
    adminOnly: true,
    items: [
      {
        title: "Usage Details",
        icon: ProfileIcon,
        href: "/app/usage/detail"
      },
      {
        title: "Licenses in Use",
        icon: LicenseThirdPartyIcon,
        href: "/app/licenses/used"
      },
      {
        title: "Usage Chart",
        icon: ChartLineData02Icon,
        href: "/app/usage/chart"
      },
      {
        title: "Usage Heat Map",
        icon: AnalyticsUpIcon,
        href: "/app/usage/heatmap"
      }
    ]
  },
  {
    title: "Licensing Alerts",
    icon: Alert01Icon,
    adminOnly: true,
    href: "/app/alerts"
  },
  {
    title: "Admin Center",
    icon: MicrosoftAdminIcon,
    adminOnly: true,
    items: [
      {
        title: "Manage Users",
        icon: UserSettings01Icon,
        href: "/app/admin/users/manage"
      },
      {
        title: "Manage User Groups",
        icon: UserGroupIcon,
        href: "/app/admin/groups/manage"
      },
      {
        title: "Welcome Emails",
        icon: MailAccount01Icon,
        href: "/app/admin/emails/manage"
      },
      {
        title: "Custom User Fields",
        icon: PropertyEditIcon,
        href: "/app/admin/customfields/manage"
      },
      {
        title: "License Management",
        icon: LicenseMaintenanceIcon,
        href: "/app/admin/licenseManagement"
      },
      {
        title: "Active Devices",
        icon: ComputerSettingsIcon,
        href: "/app/admin/deviceManagement"
      },
      {
        title: "Portal Configuration",
        icon: Settings05Icon,
        href: "/app/admin/config/portal"
      },
    ]
  },
  {
    title: "Request Training",
    icon: TeachingIcon,
    href: "/app/training"
  },
];

function renderNavItems({ items, user, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, user, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, user, item, depth }) {
  const key = item.title + depth;

  if (item.adminOnly && !user.admin) {
    return acc;
  }

  if (item.userOnly && user.admin) {
    return acc;
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          user,
          pathname,
          items: item.items
        })}
      </NavItem>
    );

  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
    
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  navScrollBar:{
    background: theme.palette.primary.main,
    paddingBottom: "64px"
  },
  divider:{
    background: theme.palette.text.secondary
  },
  companyLogo:{
    width: "100%",
    maxHeight: "3.0rem",
    objectFit: "contain", // This will prevent the logo from being stretched or cut off
    objectPosition: "left",
    display: "block"
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256
    //top: 64,
    //height: "calc(100% - 64px)"
  },
  myTicketsButton: {
    width: "100%",
    backgroundColor: "#212B36",
    color: "#fff",
    border: "1px solid #637381",
    padding: "10px",
    borderRadius: "8px",
    "&:hover":{
      backgroundColor: "#637381",
    }
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();  
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { user,appInsightsProperties,sessionId, company } = useAuth();
  const location = useLocation();
  const { settings } = useSelector(state => state.companySettings);

  appInsights.context.session.id = sessionId;

  // Track the initial page view when the app loads
  appInsights.trackPageView({
    properties: appInsightsProperties 
  });

  useEffect(() => {
    Promise.all([dispatch(getCompanySettings())])
    .then(() => {})
    .catch((error) => {
         // Track exception with Application Insights
         appInsights.trackException({
          exception: error,
          properties: {
            ...appInsightsProperties,
            message: error.message,
            stack: error.stack,
            name: error.name,
            sessionId: sessionId, // Included session ID from context
          }
        });
    });
  }, [dispatch, isMountedRef,user, sessionId, company]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar className={classes.navScrollBar} options={{ suppressScrollX: true }}>
        <Box m={2} mb={0}>
        <Hidden lgUp>
          <RouterLink to="/" className={classes.header}>
            <Box>
              {settings?.companyLogo ? (
                <img
                  className={classes.companyLogo}
                  srcSet={`${base64ToImage(settings.companyLogo)} 1x, ${base64ToImage(settings.companyLogo)} 2x`}
                  src={base64ToImage(settings.companyLogo)}
                  alt={`${settings.companyTitle}`}         
                  style={{ maxWidth: '100%', maxHeight: '3rem', objectFit: 'contain' }}
                ></img>
              ) : (
                <img
                  className={classes.companyLogo}
                  src="/static/civilgeo_white_logo.png"
                  alt={`CivilGEO Inc.`}
                ></img>
                // <SmallLogo />
              )}
              {/* <Typography variant="h3"> {settings && settings.companyTitle ? settings.companyTitle : "Customer Portal"}</Typography> */}
            </Box>
          </RouterLink>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/" className={classes.header}>
            <Box>
              {settings?.companyLogo ? (
                <img
                  className={classes.companyLogo}
                  srcSet={`${base64ToImage(settings.companyLogo)} 1x, ${base64ToImage(settings.companyLogo)} 2x`}
                  src={base64ToImage(settings.companyLogo)}
                  alt={`${settings.companyTitle}`}
                  style={{ maxWidth: '100%', maxHeight: '3rem', objectFit: 'contain' }}
                ></img>
              ) : (
                <img
                  className={classes.companyLogo}
                  src="/static/CivilGEO_White_Logo.png"
                  alt={`CivilGEO Inc.`}
                ></img>
              )}
              {/* <Typography variant="h1">{settings && settings.companyTitle ? settings.companyTitle : "Customer Portal"}</Typography> */}
            </Box>
          </RouterLink>
        </Hidden>
        </Box>

        <Box p={2}>
          {renderNavItems({
            items,
            user,
            pathname: location.pathname
          })}
        </Box>
      </PerfectScrollbar>
      {/* <Box p={2} style={{position: "absolute", bottom:20, width:"100%"}}>
          <Button
            className={classes.myTicketsButton}
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/app/tickets/all"
          >
            My Tickets
          </Button>
        </Box> */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default withAITracking(reactPlugin,NavBar);