import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
  licenseStatuses: []
};

const slice = createSlice({
  name: "licenseStatuses",
  initialState,
  reducers: {
    getLicenseStatuses(state, action) {
      const licenseStatuses = action.payload;
      
      state.licenseStatuses = licenseStatuses;
    }
  }
});

export const reducer = slice.reducer;

export const getLicenseStatuses = () => async dispatch => {
  const response = await axios.get("/api/licenses/status");

  dispatch(slice.actions.getLicenseStatuses(response.data));
};

export default slice;
