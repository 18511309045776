export default [
  {
    text: "GeoHECRAS",
    description: "Rapidly construct 1D & 2D HEC-RAS models, producing better and more accurate results. Accelerate engineering workflows and streamline analysis review.",
    value: "9a7b965d-ec50-4fe8-9141-f98d6ea7e2e6",
    image: "/static/images/products/GeoHECRAS.ico",
    nameImage:"/static/images/products/GeoHECRAS_Registered.png",
    cardImage: "/static/images/products/GeoHECRAS-Card-Image.webp",
    variants: [
      // { text: "Starter", value: "00000000-0000-0000-0000-000000000000" },
      { text: "GeoHECRAS 1D", value: "9a7b965d-ec50-4fe8-9141-f98d6ea7e2e6" },
      { text: "GeoHECRAS 1D & 2D", value: "4e27ae81-6e93-4c1f-ac15-699be660cd0a" }
    ]
  },
  //{ text: "GeoHECRAS 2D", value: "4e27ae81-6e93-4c1f-ac15-699be660cd0a" },
  {
    text: "GeoHECHMS",
    description: "Greatly speed up the process for creating, analyzing, and reviewing stormwater models using the industry‑standard Army Corps of Engineers HEC‑HMS.",
    value: "55eebc12-0b34-47b4-b2c0-5207961ed2b0",
    image: "/static/images/products/GeoHECHMS.ico",
    nameImage:"/static/images/products/GeoHECHMS_Registered.png",
    cardImage: "/static/images/products/GeoHECHMS-Card-Image.webp",
    variants: [
      // { text: "Starter", value: "00000000-0000-0000-0000-000000000000" },
      // { text: "Standard", value: "00000000-0000-0000-0000-000000000000" },
      { text: "GeoHECHMS", value: "55eebc12-0b34-47b4-b2c0-5207961ed2b0" }
    ]
  },
  {
    text: "GeoSTORM",
    description: "Accelerate the process of designing, analyzing, and reviewing urban and rural stormwater models in an AutoCAD compatible user interface.",
    value: "dca86248-c9c2-432e-a38e-1dc87b1cec16",
    image: "/static/images/products/GeoSTORM.ico",
    nameImage:"/static/images/products/GeoSTORM_Registered.png",
    cardImage: "/static/images/products/GeoSTORM-Card-Image.webp",
    variants: [
      { text: "GeoSTORM Starter", value: "dca86248-c9c2-432e-a38e-1dc87b1cec16" },
      { text: "GeoSTORM Standard", value: "1329ab1a-012d-4bc7-892d-c40a66c35ba1" },
      { text: "GeoSTORM Professional", value: "1ace0b8c-4d58-47d7-91f1-82c893778216" }
    ]
  }
];
