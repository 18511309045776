import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";
const initialState={
    user:null
};

const slice = createSlice({
    name:"updateProfile",
    initialState,
    reducers:{
        UpdateProfileDetails(state,action) {
            const updatedUser = action.payload;
            state.user=updatedUser;
        }
    }
    
});
export const reducer = slice.reducer;

export const UpdateProfileDetails = (userId,updatedProfileInfo)=>async dispatch=>{
   
   const response = await axios.post("/api/account/updateUserProfile",{
        userId,
        updatedProfileInfo
    });
    dispatch(slice.actions.UpdateProfileDetails(response.data));
};

export default slice;