import axios from "axios";

const axiosInstance = axios.create(
  {
    //baseURL: 'https://localhost:44384/'                                  // Localhost 
    //baseURL: 'https://licenseportalapistaging.azurewebsites.net/'        // Staging API
    baseURL: 'https://customerapi.civilgeo.com/'                         // Production API
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
