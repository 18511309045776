// Function to get previous page URL from local storage
const getRedirectUrl = () => {
  return localStorage.getItem('redirectUrl');
};

// Function to set previous page URL in local storage
const setRedirectUrl = (url) => {
  localStorage.setItem('redirectUrl', url);
};

const addRedirectUrlToPath = (currentUrl, redirectUrl) => {
  return currentUrl+"?redirectUrl="+redirectUrl;
}

export {getRedirectUrl, setRedirectUrl, addRedirectUrlToPath}