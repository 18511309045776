import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import SmallLogo from "src/components/SmallLogo";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Avatar,
  Typography
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { THEMES } from "src/constants";
import Account from "./Account";
import Notifications from "./Notifications";
import { getCompanySettings } from "src/slices/admin/companySettings";
import { useSelector } from "src/store";
import base64ToImage from "src/utils/imageUtils";
import { useDispatch } from "react-redux";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import FreshChatView from "src/components/FreshChatView";
import useAuth from "src/hooks/useAuth";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer - 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: "0px 1px 1px 0.5px rgba(5, 44, 101, 0.06)",
          backgroundColor: theme.palette.background.default
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64,
    left: 256,
    width: "calc(100% - 256px)",
    [theme.breakpoints.down("md")]:{
      left: 0,
      width: "100%",
      }
  },
  header: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "& div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& h1": {
      [theme.breakpoints.down("md")]: {
        fontSize: "1.4rem"
      }
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        height: "1.4rem"
      },
      height: "1.6rem",
      marginRight: "8px"
    }
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { settings } = useSelector(state => state.companySettings);
  const {user, isAuthenticated} = useAuth();
  useEffect(() => {
    Promise.all([dispatch(getCompanySettings())])
      .then(() => {})
      .catch(() => {});
  }, [dispatch, isMountedRef]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';      
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.type = "image/png";
    link.rel="shortcut icon"
    link.sizes="16x16";
    link.id="favicon"
    link.href =( settings && settings.companyLogo )? base64ToImage(settings.companyLogo) : "%PUBLIC_URL%/favicon.ico";
  }, [settings]);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>      
      <Toolbar className={classes.toolbar}>
        {/* {isAuthenticated && <FreshChatView user={user}/>} */}
        <Hidden lgUp>
          <IconButton color="primary" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <RouterLink to="/" className={classes.header}>
            <Box>
              {/* {settings && settings.companyLogo ? (
                <img
                  src={base64ToImage(settings.companyLogo)}
                  alt={`${settings.companyTitle}`}         
                ></img>
              ) : (
                <img
                  src="/static/civilgeo_white_logo.png"
                  alt={`CivilGEO Inc.`}
                ></img>
                // <SmallLogo />
              )} */}
              {/* <Typography variant="h3"> {settings && settings.companyTitle ? settings.companyTitle : "Customer Portal"}</Typography> */}
            </Box>
          </RouterLink>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/" className={classes.header}>
            <Box>
              {/* {settings && settings.companyLogo ? (
                <img
                  src={base64ToImage(settings.companyLogo)}
                  alt={`${settings.companyTitle}`}                  
                ></img>
              ) : (
                <img
                  style={{height: "2.6rem"}}
                  src="/static/CivilGEO_White_Logo.png"
                  alt={`CivilGEO Inc.`}
                ></img>
              )} */}
              {/* <Typography variant="h1">{settings && settings.companyTitle ? settings.companyTitle : "Customer Portal"}</Typography> */}
            </Box>
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Notifications />
        <Box ml={2} mr={4}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
