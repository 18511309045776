import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
  serviceStatus: {
    operational: true,
    message: ""
  }
};

const slice = createSlice({
  name: "serviceStatus",
  initialState,
  reducers: {
    getServiceStatus(state, action) {
      const serviceStatus = action.payload;
      
      state.serviceStatus = serviceStatus;
    }
  }
});

export const reducer = slice.reducer;

export const getServiceStatus = () => async dispatch => {
  const response = await axios.get("/api/serviceStatus");

  dispatch(slice.actions.getServiceStatus(response.data));
};

export default slice;
