import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import mock from "src/utils/mock";

let emails = [
  {
    id: "5e8882e440f6322fa399eeb8",
    name: "To Engineering",
    subject: "Welcome Engineers!",
    customText: "Test custom content #1"
  },
  {
    id: "5e8882eb5f8ec686220ff131",
    name: "To Land Development",
    subject: "Welcome Land Development Engineers!",
    customText: "Test custom content #2"
  },
  {
    id: "5e8882f1f0c9216396e05a9b",
    name: "To Water Resources",
    subject: "Welcome Water Resources Engineers!",
    customText: "Test custom content #3"
  }
];
let defaultEmail = "5e8882eb5f8ec686220ff131";
let emailReplyAddress = "test@civilgeo.com";

mock.onGet("/api/admin/welcomeEmails/emails").reply(request => {
  return [200, { emails }];
});

mock.onPost("/api/admin/welcomeEmails/emails/new").reply(request => {
  try {
    const { name, subject, customText } = JSON.parse(request.data);
    const email = {
      id: uuidv4(),
      name,
      subject,
      customText
    };

    emails = [...emails, email];

    return [200, { email }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/admin/welcomeEmails/emails/update").reply(request => {
  try {
    const { emailId, update } = JSON.parse(request.data);
    let email = null;

    emails = _.map(emails, _email => {
      if (_email.id === emailId) {
        _.assign(_email, { ...update });
        email = _email;
      }

      return _email;
    });

    return [200, { email }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/admin/welcomeEmails/emails/remove").reply(request => {
  try {
    const { emailIds } = JSON.parse(request.data);

    emails = _.reject(emails, email => emailIds.includes(email.id));

    return [200, { emailIds }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onGet("/api/admin/welcomeEmails/defaultEmail").reply(request => {
  return [200, { defaultEmail }];
});

mock.onPost("/api/admin/welcomeEmails/defaultEmail/update").reply(request => {
  try {
    const { emailId } = JSON.parse(request.data);

    defaultEmail = emailId;

    return [200, { defaultEmail }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onGet("/api/admin/welcomeEmails/replyEmail").reply(request => {
  return [200, { emailReplyAddress }];
});

mock.onPost("/api/admin/welcomeEmails/replyEmail/update").reply(request => {
  try {
    const { emailAddress } = JSON.parse(request.data);

    emailReplyAddress = emailAddress;

    return [200, { emailReplyAddress }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
