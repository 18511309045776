import axios from "src/utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProducts: []
};

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    getAllProducts(state, action) {
      const products = action.payload;

      state.allProducts = products;
    }
  }
});

export const reducer = slice.reducer;

export const getAllProducts = () => async dispatch => {
  const response = await axios.get("/api/products/all");

  dispatch(slice.actions.getAllProducts(response.data));
};

export default slice;
