import _ from "lodash";
import mock from "src/utils/mock";
import moment from "moment";

let notifications = [
  {
    id: "5e8883f1b51cc1956a5a1ec0",
    createdAt: moment()
      .subtract(2, "hours")
      .toDate()
      .getTime(),
    description: "Dummy text",
    title: "Info Notification",
    type: "info",
    href: "/app/licenses/add"
  },
  {
    id: "5e8883f7ed1486d665d8be1e",
    createdAt: moment()
      .subtract(1, "day")
      .toDate()
      .getTime(),
    description: "Dummy text",
    title: "Warning Notification",
    type: "warning",
    href: "/app/licenses/add"
  },
  {
    id: "5e8883fca0e8612044248ecf",
    createdAt: moment()
      .subtract(3, "days")
      .toDate()
      .getTime(),
    description: "Dummy text",
    title: "Error Notification",
    type: "error",
    href: "/app/licenses/add"
  }
];

mock.onGet("/api/notifications").reply(200, { notifications });

mock.onPost("/api/notifications/clear").reply(request => {
  try {
    const { notificationIds } = JSON.parse(request.data);

    notifications = _.reject(notifications, notification =>
      notificationIds.includes(notification.id)
    );

    return [200, {}];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
