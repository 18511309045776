import React from "react";
import useAuth from "../hooks/useAuth";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { setRedirectUrl } from "src/utils/pathUtils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  
  if (!isAuthenticated) {    
    return <Redirect to="/login" />;
  }

  if(user.isTempPassword){
    return <Redirect to="/app/changePassword" />;  
  }

  if(user.twoFactorEnabled && !user.isCodeVerified){
    return <Redirect to="/app/twoFactorVerification" />;  
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
