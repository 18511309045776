import _ from "lodash";
import mock from "src/utils/mock";
import moment from "moment";

let allLicenses = [
  {
    licenseId: "5e8882e440f6322fa399eeb8",
    productName: "GeoHECRAS",
    purchasedOn: moment().subtract({ days: 50 }),
    type: "Stand Alone",
    users: 13,
    validUntil: moment().add({ days: 30 })
  },
  {
    licenseId: "5e8882eb5f8ec686220ff131",
    productName: "GeoHECRAS 2D",
    purchasedOn: moment().subtract({ days: 60 }),
    type: "Cloud",
    users: 5,
    validUntil: moment().add({ days: 10 })
  },
  {
    licenseId: "5e8882f1f0c9216396e05a9b",
    productName: "GeoHECHMS",
    purchasedOn: moment().subtract({ days: 20 }),
    type: "Network Floating",
    users: 3,
    validUntil: moment().add({ days: 5 })
  }
];

let usedLicenses = [
  {
    usedLicenseId: "5e8882e440f6322fa399eeb8",
    productName: "GeoHECRAS",
    computerName: "Madison-007",
    machineCode: "4638503985",
    ipAddress: "192.168.1.1",
    activatedOn: moment().subtract({ days: 5 }),
    validUntil: moment().add({ days: 10 })
  },
  {
    usedLicenseId: "5e8882eb5f8ec686220ff131",
    productName: "GeoHECRAS",
    computerName: "Madison-010",
    machineCode: "4850274952",
    ipAddress: "192.168.1.2",
    activatedOn: moment().subtract({ days: 7 }),
    validUntil: moment().add({ days: 12 })
  },
  {
    usedLicenseId: "5e8882f1f0c9216396e05a9b",
    productName: "GeoHECRAS 2D",
    computerName: "Madison-005",
    machineCode: "3943485709",
    ipAddress: "192.168.1.3",
    activatedOn: moment().subtract({ days: 3 }),
    validUntil: moment().add({ days: 5 })
  }
];

const licenseStatuses = {
  activeLicenses: 3,
  expiringLicenses: 1,
  expiredLicenses: 1
};

mock.onGet("/api/licenses/all").reply(200, { allLicenses });

mock.onGet("/api/licenses/used").reply(() => {
  return [200, { usedLicenses }];
});

mock.onPost("/api/licenses/used/remove").reply(request => {
  try {
    const { licenseIds } = JSON.parse(request.data);

    usedLicenses = _.reject(usedLicenses, license =>
      licenseIds.includes(license.id)
    );

    return [200, {}];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onGet("/api/licenses/status").reply(200, { licenseStatuses });

mock.onGet("/api/licenses/overview").reply(request => {
  const licenseOverview = [
    {
      productId: "geohecras",
      productName: "GeoHECRAS",
      quantity: 5,
      used: Math.round(Math.random() * 10),
      validUntil: moment()
        .add(2, "months")
        .add(12, "days")
        .toDate(),
      lastUsed: moment().toDate(),
      downloadUrl: "https://downloads.civilgeo.com/installer/geohecras",
      computersUsedOn: 15
    },
    {
      productId: "geohecras2d",
      productName: "GeoHECRAS 2D",
      quantity: 3,
      used: Math.round(Math.random() * 10),
      validUntil: moment()
        .add(1, "months")
        .add(5, "days")
        .toDate(),
      lastUsed: moment().toDate(),
      downloadUrl: "https://downloads.civilgeo.com/installer/geohecras2d",
      computersUsedOn: 12
    },
    {
      productId: "geohechms",
      productName: "GeoHECHMS",
      quantity: 7,
      used: Math.round(Math.random() * 10),
      validUntil: moment()
        .add(3, "months")
        .add(2, "days")
        .toDate(),
      lastUsed: moment().toDate(),
      downloadUrl: "https://downloads.civilgeo.com/installer/geohechms",
      computersUsedOn: 20
    }
  ];

  return [200, { licenseOverview }];
});

mock.onPost("/api/licenses/add").reply(request => {
  console.log("Got add license request:");
  console.log(JSON.stringify(request.data));

  return [200, {}];
});
