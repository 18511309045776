import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";

const initialState = {
  productAccess: [],
  productLicenses: [],
  isEditDialogOpen: false,
  selectedUserIds: [],
  selectedProductIds: [],
  assignedProducts: []
};

const slice = createSlice({
  name: "productAccess",
  initialState,
  reducers: {
    getCompanyProductAccess(state, action) {
      const productAccess = action.payload;
      state.productAccess = productAccess;
    },
    getCompanyProductLicenses(state, action) {
      const productLicenses = action.payload;
      state.productLicenses = productLicenses;
    },
    selectUserToEditProductAccess(state, action) {
      const { userId, assignedProducts } = action.payload;
      state.assignedProducts = assignedProducts;
      state.selectedUserIds = [userId];
      state.isEditDialogOpen = true;
    },
    selectUsersToEditProductAccess(state, action) {
    const { userIds, assignedProducts } = action.payload;

      state.assignedProducts = assignedProducts;
      state.selectedUserIds = userIds;
    },
    unselectUserId(state, action) {
      const { userId, productVariantIds } = action.payload;
      state.selectedUserIds = state.selectedUserIds.filter(u => u !== userId);
    },
    clearUserSelection(state, action) {
      const { userId, productVariantIds } = action.payload;
      state.selectedUserIds = [];
      state.assignedProducts = [];
    },
    selectProductId(state, action) {
      const { productId } = action.payload;
      state.selectedProductIds = state.selectedProductIds
        .filter(p => p !== productId)
        .concat([productId]);
    },
    unselectProductId(state, action) {
      const { productId } = action.payload;
      state.selectedProductIds = state.selectedProductIds.filter(
        p => p !== productId
      );
    },
    selectVariantId(state, action) {
      const assignedProduct = action.payload;            
      state.assignedProducts = state.assignedProducts
        .filter(p => p.productVariantId !== assignedProduct.productVariantId)
        .concat(assignedProduct);      
    },
    unselectVariantId(state, action) {
      const assignedProduct = action.payload;
      state.assignedProducts = state.assignedProducts
        .filter(p => p.productVariantId !== assignedProduct.productVariantId);
    },
    openEditDialog(state) {
      state.isEditDialogOpen = true;
    },
    closeEditDialog(state) {
      state.isEditDialogOpen = false;
    }
  }
});

export const reducer = slice.reducer;

export const getCompanyProductAccess = () => async dispatch => {
  const response = await axios.get("/api/admin/productAccess");

  dispatch(slice.actions.getCompanyProductAccess(response.data));
};

export const getCompanyProductLicenses = () => async dispatch => {
  const response = await axios.get("/api/admin/productAccess/manageProducts");

  dispatch(slice.actions.getCompanyProductLicenses(response.data));
};

export const selectUserToEditProductAccess = (
  userId,
  assignedProducts
) => dispatch => {
  dispatch(
    slice.actions.selectUserToEditProductAccess({
      userId,
      assignedProducts
    })
  );
};

export const selectUsersToEditProductAccess = (
  userIds,
  assignedProducts
) => dispatch => {
  dispatch(
    slice.actions.selectUsersToEditProductAccess({
      userIds,
      assignedProducts
    })
  );
};

export const unselectUserId = (userId, productVariantIds) => dispatch => {
  dispatch(slice.actions.unselectUserId({ userId, productVariantIds }));
};

export const clearUserSelection = (userId, productVariantIds) => dispatch => {
  dispatch(slice.actions.clearUserSelection({ userId, productVariantIds }));
};

export const selectProductId = productId => dispatch => {
  dispatch(slice.actions.selectProductId({ productId }));
};

export const unselectProductId = productId => dispatch => {
  dispatch(slice.actions.unselectProductId({ productId }));
};

export const selectVariantId = assginedProduct => dispatch => {  
  dispatch(slice.actions.selectVariantId(assginedProduct));  
};

export const unselectVariantId = assginedProduct => dispatch => {
  dispatch(slice.actions.unselectVariantId(assginedProduct));
};

export const updateUserProductAccess = (
  userIds,
  assignedProducts
) => async dispatch => {
  const response = await axios.post("/api/admin/productAccess/update", {
    userIds,
    assignedProducts
  });
  dispatch(slice.actions.closeEditDialog());
};

export const openEditDialog = () => dispatch => {
  dispatch(slice.actions.openEditDialog());
};

export const closeEditDialog = () => dispatch => {
  dispatch(slice.actions.closeEditDialog());
};

export default slice;
