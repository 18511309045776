import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import mock from "src/utils/mock";

let users = [
  {
    id: "5e8882e440f6322fa399eeb6",
    firstName: "Mark",
    lastName: "Henderson",
    emailAddress: "mark.henderson@civilgeo.com",
    groupId: "5e8882e440f6322fa399eeb8",
    admin: false
  },
  {
    id: "5e8882e440f6322fa399eeb7",
    firstName: "Bob",
    lastName: "Klemp",
    emailAddress: "bob.klemp@civilgeo.com",
    groupId: "5e8882e440f6322fa399eeb8",
    admin: false
  },
  {
    id: "5e8882e440f6322fa399eeb8",
    firstName: "Alex",
    lastName: "Parker",
    emailAddress: "alex.parker@civilgeo.com",
    groupId: "5e8882e440f6322fa399eeb8",
    admin: false
  },
  {
    id: "5e8882eb5f8ec686220ff131",
    firstName: "Ashok",
    lastName: "Mani",
    emailAddress: "ashok.mani@civilgeo.com",
    groupId: "5e8882f1f0c9216396e05a9c",
    admin: true
  },
  {
    id: "5e8882f1f0c9216396e05a9b",
    firstName: "Chris",
    lastName: "Maeder",
    emailAddress: "chris@civilgeo.com",
    groupId: "5e8882e440f6322fa399eeb8",
    admin: true
  }
];

mock.onGet("/api/admin/users").reply(request => {
  return [200, { users }];
});

mock.onPost("/api/admin/users/new").reply(request => {
  try {
    const { firstName, lastName, emailAddress, groupId, admin } = JSON.parse(
      request.data
    );
    const user = {
      id: uuidv4(),
      firstName,
      lastName,
      emailAddress,
      groupId,
      admin
    };

    users = [...users, user];

    return [200, { user }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/admin/users/update").reply(request => {
  try {
    const { userId, update } = JSON.parse(request.data);
    let user = null;

    users = _.map(users, _user => {
      if (_user.id === userId) {
        _.assign(_user, { ...update });
        user = _user;
      }

      return _user;
    });

    return [200, { user }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/admin/users/remove").reply(request => {
  try {
    const { userIds } = JSON.parse(request.data);

    users = _.reject(users, user => userIds.includes(user.id));

    return [200, { userIds }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
