import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  defaultCountry: null
};

const slice = createSlice({
  name: "savedDefaults",
  initialState,
  reducers: {
    updateDefaults(state, action) {
      
    }
  }
});

export const reducer = slice.reducer;

export const getDefaults = () => async dispatch => {
  let defaults = {};

  try {
    defaults = JSON.parse(localStorage.getItem("savedDefaults"));
  } finally {
    dispatch(slice.actions.updateDefaults({ defaults }));
  }
};

export const setDefaults = defaults => async dispatch => {
  localStorage.setItem("savedDefaults", JSON.stringify(defaults));

  dispatch(slice.actions.updateDefaults({ defaults }));
};

export default slice;
