import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import mock from "src/utils/mock";

let groups = [
  {
    id: "5e8882e440f6322fa399eeb8",
    name: "Engineering",
    welcomeEmailId: "5e8882e440f6322fa399eeb8",
    userIds: ["5e8882e440f6322fa399eeb6", "5e8882e440f6322fa399eeb7"]
  },
  {
    id: "5e8882eb5f8ec686220ff131",
    name: "Land Development",
    parentGroup: "5e8882e440f6322fa399eeb8",
    welcomeEmailId: "5e8882eb5f8ec686220ff131",
    userIds: ["5e8882e440f6322fa399eeb8"]
  },
  {
    id: "5e8882f1f0c9216396e05a9b",
    name: "Water Resources",
    parentGroup: "5e8882e440f6322fa399eeb8",
    welcomeEmailId: "5e8882f1f0c9216396e05a9b",
    userIds: ["5e8882eb5f8ec686220ff131"]
  },
  {
    id: "5e8882f1f0c9216396e05a9c",
    name: "IT Support",
    welcomeEmailId: "5e8882f1f0c9216396e05a9b",
    userIds: ["5e8882f1f0c9216396e05a9b"]
  }
];

mock.onGet("/api/admin/groups").reply(request => {
  return [200, { groups }];
});

mock.onPost("/api/admin/groups/new").reply(request => {
  try {
    const { name, parentGroupId, welcomeEmailId } = JSON.parse(request.data);
    const group = {
      id: uuidv4(),
      name,
      parentGroupId,
      welcomeEmailId,
      userIds: []
    };

    groups = [...groups, group];

    return [200, { group }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/admin/groups/update").reply(request => {
  try {
    const { groupId, update } = JSON.parse(request.data);
    let group = null;

    groups = _.map(groups, _group => {
      if (_group.id === groupId) {
        _.assign(_group, { ...update });
        group = _group;
      }

      return _group;
    });

    return [200, { group }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

mock.onPost("/api/admin/groups/remove").reply(request => {
  try {
    const { groupIds } = JSON.parse(request.data);

    groups = _.reject(groups, group => groupIds.includes(group.id));

    return [200, { groupIds }];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
