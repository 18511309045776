const leftPadArray = function(arr, len, fill) {
  return Array(len)
    .fill(fill)
    .concat(arr)
    .slice(arr.length);
};

var rightPadArray = function(arr, len, fill) {
  return arr.concat(Array(len).fill(fill)).slice(0, len);
};

export { leftPadArray, rightPadArray };
