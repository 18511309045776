import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import React, { useState } from "react";

import CopyIcon from "@material-ui/icons/FileCopy";
import Page from "src/components/Page";
import getDebugInfo from "src/utils/getDebugInfo";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  details: {
    position: "relative"
  },
  detailsText: {
    whiteSpace: "pre-wrap",
    maxHeight: "300px",
    overflow: "auto"
  },
  copyDetails: {
    position: "absolute",
    top: 20,
    right: 20,
    zIndex: 10
  }
}));

const ErrorView = ({ error, errorInfo }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const [detailsOpen, setDetailsOpen] = useState(false);

  const getErrorDetails = () => {
    return (
      (errorInfo.componentStack || "").replace(/\n|\r/, "") +
      `\n\n${getDebugInfo()}`
    );
  };

  const copyDetails = () => {
    let text = "";

    if (error) text += `${error.toString()}\n`;
    text += getErrorDetails();

    try {
      navigator.clipboard.writeText(text);
      enqueueSnackbar("Error copied");
    } catch (error) {
      enqueueSnackbar("Failed to copy error",{ variant: "error" });
    }
  };

  return (
    <Page className={classes.root} title="404: Not found">
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? "h4" : "h1"}
          color="textPrimary"
        >
          Something went wrong
        </Typography>
        <Box className={classes.container} mt={2}>
          {!detailsOpen ? (
            <Button
              color="primary"
              onClick={() => {
                setDetailsOpen(true);
              }}
              variant="text"
            >
              Details
            </Button>
          ) : (
            <Paper className={classes.details}>
              <Tooltip title="Copy" className={classes.copyDetails}>
                <IconButton onClick={copyDetails}>
                  <CopyIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Box className={classes.detailsText} p={2}>
                <Typography variant="body1">
                  {error && error.toString()}
                </Typography>
                <Typography variant="body2">{getErrorDetails()}</Typography>
              </Box>
            </Paper>
          )}
        </Box>
        <Box className={classes.container} mt={6}>
          <Button
            display="block"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
            variant="outlined"
          >
            Reload
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default ErrorView;
