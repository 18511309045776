import axios from "src/utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import products from "src/static/products";

const initialState = {
  assignedProducts: []
};

const slice = createSlice({
  name: "userProductAccess",
  initialState,
  reducers: {
    getAssignedProducts(state, action) {
      const assignedProducts = action.payload;

      state.assignedProducts = assignedProducts;
    }
  }
});

export const reducer = slice.reducer;

export const getAssignedProducts = () => async dispatch => {
  const response = await axios.get("/api/productAccess/");
  const productVariantIds = response.data.map(item => item.productVariantId);

  // Filter response.data based on multiple conditions
  const filteredData = response.data.filter(item => {
    // Check if productVariantId exists in products and its variants.text includes "GeoSTORM"
    return !item.licenseType.includes("Network") && // Check if licenseType is not "Network"
           productVariantIds.includes(item.productVariantId) && // Check if productVariantId exists in productVariantIds
           products.some(product => (
             product.variants.some(variant => (
               variant.value === item.productVariantId &&
               variant.text.includes('GeoSTORM')
             ))
           ));
  });
  dispatch(slice.actions.getAssignedProducts(filteredData));
};

export default slice;
